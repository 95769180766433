<template>
   <section class="cont room-set-cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>PMS</el-breadcrumb-item>
            <el-breadcrumb-item>房间管理</el-breadcrumb-item>
            <el-breadcrumb-item>房型设置</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <div class="page">
         <div class="left">
            <div class="addbox">
               <div class="top">
                  <el-form>
                     <el-form-item label="单元/楼栋" prop="buildUnitIdQ" style="width: 315px;">
                        <el-cascader
                              style="margin-top: 5px;"
                              :key="buildKey"
                              v-model="buildUnitIdQ"
                              :options="buildList"
                              @change="changeBuilding"
                              clearable
                              class="width-230 m-right-10">
                        </el-cascader>
                     </el-form-item>
                  </el-form>
               </div>
               <div class="top">
                  <el-form>
                     <el-form-item label=" 楼层名 ：" prop="buildUnitIdQ" style="width: 300px;">
                        <el-input v-model="frool" type="number"   placeholder="请输入楼层数字" style="width: 50%;"></el-input>
                        <el-button type="primary" class="somebtn"  style="margin: 5px 0 0 5px; " @click="addfroollist">添加</el-button>
                     </el-form-item>
                  </el-form>
               </div>
               <div class="frool">
                  <div class="item" v-for="(item,index) in  floors" :key="index">
                     <el-button type="text"  style="margin-left: 20px" @click="getFloorClick(item)">{{item.value}}楼</el-button>
                     <el-button type="text"  style="margin-left: 50px;color: red;float: right;margin-right: 20px;" @click="editUnit(item.value)"><i class="el-icon-delete"></i> 删除楼层</el-button>
                  </div>
               </div>
            </div>
         </div>
         <div class="right">
            <div class="right-box">
               <div class="right-title">
                  <span class="froolnum">【{{floorQ}}楼】 </span> 房间属性
                  <el-button type="primary"  class="somebtn"  style="margin-left: 20px" @click="getbtntype('添加')" >添加房间</el-button>
                  <el-button type="primary"  class="somebtn" @click="getbtntype('批量')">批量生成房间</el-button>
                  <el-button type="primary"  class="somebtn" @click="getallroomdata">查看所有房间</el-button>
               </div>
               <el-table
                     :data="roomlist"
                     border>
<!--                  :header-cell-style="{'text-align':'center'}"-->
<!--                  :cell-style="{'text-align':'center'}"-->

                  <el-table-column
                        prop="roomNo"
                        label="房号">
                  </el-table-column>
                  <el-table-column
                        prop="roomTypeName"
                        label="房型">
                  </el-table-column>
                  <!--            <el-table-column-->
                  <!--                prop="aspect"-->
                  <!--                label="朝向">-->
                  <!--            </el-table-column>-->
                  <!--            <el-table-column-->
                  <!--                prop="roomTags"-->
                  <!--                label="标签">-->
                  <!--            </el-table-column>-->
                  <el-table-column
                        prop="remark"
                        label="备注">
                  </el-table-column>
                  <el-table-column
                        prop="address"
                        label="操作">
                     <template slot-scope="item">
                        <el-button type="text"  @click="getbtntype('编辑',item.row)" >编辑</el-button>
                        <el-button type="text" style="color: red"  @click="getbtntype('删除',item.row)" >删除</el-button>
                     </template>
                  </el-table-column>
               </el-table>
               <!-- 分页 -->
               <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
            </div>
         </div>
      </div>
      <!--    添加/编辑弹窗-->
      <el-dialog :title="`${btntype}房间`" :visible.sync="centerDialogVisible"  @closed="closed"  width="550px">
         <el-form  class="form-dialog-box" ref="addroomform" v-if="btntype !=='删除' " :rules="rules" :model="addroomform">
            <el-form-item label="单元/楼栋" prop="buildUnitIdQ">
               <el-cascader
                     :key="buildKey"
                     v-model="addbuildUnitIdQ"
                     :options="buildList"
                     @change="addchangeBuilding"
                     :disabled="disabled"
                     clearable
                     class="m-right-10 width-300"></el-cascader>
            </el-form-item>
            <el-form-item label="所属楼层" prop="floor">
               <el-select class="width-300"  v-model="addroomform.floor" placeholder="请选择" >
                  <el-option v-for="(item,index) in addfloors"  :disabled="disabled"  :key="index" :label="item.value" :value="item.value" >{{item.value}}</el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="房间类型" prop="roomTypeId">
               <el-select  class="width-300"  v-model="addroomform.roomTypeId"   placeholder="请选择">
                  <el-option  v-for="(item,index) in roomtypelist"  :key="index" :label="item.roomTypeName" :value="item.id" >{{item.roomTypeName}}</el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="房号" prop="roomNo">
               <el-input class="width-300"  v-model="addroomform.roomNo" @blur = "blur" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" placeholder="房号" maxlength="9" ></el-input>
            </el-form-item>
            <el-form-item label="房间描述" prop="remark">
               <el-input  class="width-300"   type="textarea" v-model="addroomform.remark" placeholder="备注不超过9个字" maxlength="9" ></el-input>
            </el-form-item>
         </el-form>
         <span class="delet_text" v-else>确定要删除该房间吗</span>
         <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button class="bg-gradient" type="primary" @click="addroomlist" v-if="btntype =='添加' ">确 定</el-button>
    <el-button class="bg-gradient" type="primary" @click="editroomitem" v-if="btntype =='编辑' ">确 定</el-button>
    <el-button class="bg-gradient" type="primary" @click="deleteitem"  v-if="btntype =='删除' ">确 定</el-button>
  </span>
      </el-dialog>
      <!-- 批量添加房间 -->
      <el-dialog title="批量添加房间" :visible.sync="addcenterDialogVisible"  >
         <el-form class="form-dialog-box" ref="addroomformlist"  :rules="rules" :model="addroomformlist">
            <el-form-item label="单元/楼栋" prop="addbuildUnitIdQ">
               <el-cascader
                     class="m-right-10 width-300"
                     :key="buildKey"
                     v-model="addbuildUnitIdQ"
                     :options="buildList"
                     @change="addchangeBuilding"
                     :disabled="disabled"
                     clearable></el-cascader>
            </el-form-item>
            <el-form-item label="所属楼层" prop="floor" v-show="selectshow">
               <el-select  class="width-300" v-model="addroomformlist.floor" @change="getRoomslist" clearable placeholder="请选择" >
                  <el-option v-for="(item,index) in addfloors"  :disabled="disabled"  :key="index" :label="item.value" :value="item.value" >{{item.value}}</el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="房间类型" prop="roomType">
               <el-select  class="width-300"  v-model="roomTypeId"   placeholder="请选择">
                  <el-option  v-for="(item,index) in roomtypelist" :disabled="disabled" :key="index" :label="item.roomTypeName" :value="item.id" >{{item.roomTypeName}}</el-option>
               </el-select>
            </el-form-item>
            <el-row class="form-row">
               <el-form-item label="房间前缀" >
                  <el-input   class="width-200"  v-model="roomNo" @change="getaddroomlist" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" placeholder="只支持输入数字" maxlength="9" ></el-input>
               </el-form-item>
               <el-form-item label="房间数量" prop="roomlength">
                  <el-input-number   class="width-200"  v-model="roomlength" @change="getaddroomlist" @blur="getaddroomlist" :min="1" :max="20" label="最大值为20"></el-input-number>
                  <div class="tips">单次批量添加房间最大值为20</div>
               </el-form-item>
            </el-row>
            <el-form-item label="过滤" prop="remark">
               <el-checkbox-group v-model="except"   class="width-300" >
                  <el-checkbox :label="item" :disabled="checkbox"  @change="getaddroomlist"  v-for="(item,index) in exceptlist" :key="index"></el-checkbox>
               </el-checkbox-group>
            </el-form-item>
            <el-form-item label="房号预览" >
               <div style="width: 100%">
                  <div v-for="(item,index) in newroomlist" :key="index" style="width: 50%;padding:10px 0;float: left">
                     <el-form-item :label="item.roomNo" >
                        <el-select v-model="item.roomTypeId"   placeholder="请选择" >
                           <el-option  v-for="(items,i) in roomtypelist" :disabled="disabled"  :key="i" :label="items.roomTypeName" :value="items.id" >{{items.roomTypeName}}</el-option>
                        </el-select>
                        <el-button type="text" style="margin-left: 10px" @click="deleteroomlist(index)">删除</el-button>
                     </el-form-item>
                  </div>
               </div>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
          <el-button @click="batchdialog">取 消</el-button>
          <el-button class="bg-gradient" type="primary" @click="addroomlistdata">确 定</el-button>
       </span>
      </el-dialog>
   </section>
</template>

<script>
import { mapState } from "vuex";
import { urlObj } from '@/api/interface'
import { pms } from '@/api/interface/pms'
export default {
   data(){
      return{
         buildList:[],//单元栋列表
         emptyFloor:[],//楼层
         floors:[],//过滤0层的楼层列表
         addfloors:[],//添加和编辑时的楼层列表
         roomNo:'',//房间号
         roomTypeId:'',//房型id
         roomslist:[],//批量添加房间时房间列表
         except:[],//过滤选中时列表
         exceptlist:[4,7],//过滤房间号
         disabled:false,//表单禁用标识
         checkbox:true,//复选框禁用标识
         roomlength:'',//批量添加长度
         roomtypelist:[],//房型列表
         froollist:[],//楼层列表
         roomlist:[],//房间列表
         newroomlist:[],//批量添加房间列表
         centerDialogVisible:false,//添加/编辑/删除弹窗
         addcenterDialogVisible:false,//批量添加弹窗
         hotelId:'',//酒店id
         selectshow:true,
         floorQ:'',//所选楼层
         total:0,//所获取数据总数
         floorinfo:'',//单元信息
         floorOpt:[],//楼层列表
         btntype:'',//弹窗标题类型
         buildUnitIdQ:[],//单元栋列表
         addbuildUnitIdQ:[],//添加时的
         buildKey:'',//单元栋标识
         frool:'',//楼层数
         limit:'',//获取每页数据量
         page:'',//页数
         item_id:'',//删除时的id
         addroomformlist:{//批量添加表单
            floor:'',
            hotelId:'',
            buildUnitId:'',
            pmsRooms:[]
         },
         addroomform:{//单房间添加表单
            floor:'',
            roomNo:'',
            hotelId:'',
            roomTypeId:'',
            buildUnitId:'',
            excludeFloor:[],    // 删除楼层
            remark:'',
            id:'',
         },
         rules: {
            roomType:[
               { required: true, message: '请选择房型', trigger: 'blur' },
            ],
            roomNo:[
               { required: true, message: '请输入房号', trigger: 'blur' },
            ],
           roomTypeId:[
             { required: true, message: '请选择房型', trigger: 'blur' },
           ],
            floor:[
               {required: true, message: '请选择楼层', trigger: 'blur'}
            ],
            buildUnitId:[
               {required: true, message: '请选择单元楼栋', trigger: 'blur'}
            ]
         }
      }
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id) {
               this.hotelId = this.hotelInfo.id
               this.addroomform.hotelId = this.hotelInfo.id
               this.getBuildList()
               this.getroomtypelist()
               this.getRooms()
            }
         },
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.page = 1
      this.addroomform.hotelId = this.hotelInfo.id
      // this.hotelId = sessionStorage.getItem('hotelId')
      this.hotelId = this.hotelInfo.id
      this.addroomform.buildUnitId = this.buildUnitIdQ[1]

      this.getBuildList()
      this.getroomtypelist()
      this.getRooms()
   },
   methods: {
      //获取栋列表
      getBuildList() {
         const url = urlObj.buildAndUnitList+'/'+this.hotelInfo.id
         const param = {}
         this.$axios.get(url, param).then(res => {
            if (res.success) {
               let buildList = res.records
               this.buildList = buildList.map(item1 => {
                  let children = []
                  if (item1.buildUnits.length > 0){
                     children = item1.buildUnits.map(item2 => {
                        return {value: item2.id, label: item2.unitName}
                     })
                  }
                  return {value: item1.id, label: item1.buildName, children}
               })
               this.buildUnitIdQ = [this.buildList[0].value,this.buildList[0].children[0].value]
               this.getFloors(this.buildUnitIdQ[1])
            }
         })
      },
      // 获取当前楼层列表
      getFloors(unitVal){
         const url = urlObj.getUnit + '/'+unitVal
         this.$axios.get(url, {}).then(res => {
            if (res.success) {
               let data = res.records
               this.floorinfo = res.records
               let beginFloor = data.beginFloor
               let endFloor = data.endFloor
               if (!endFloor || endFloor <= 0) return
               let floors = JSON.parse(JSON.stringify(this.emptyFloor))
               let obj = {}
               for (let i = beginFloor; i <= endFloor; i++) {
                  obj.value = i
                  obj.label = i
                  floors.push(obj)
                  obj = {}
               }
               if (this.floorinfo.excludeFloor == undefined)this.floorinfo.excludeFloor = ''
               if (this.btntype == '添加' ){
                  this.addfloors = floors.filter(item=>{
                     return item.value != 0
                  })
                     this.floorinfo.excludeFloor = this.floorinfo.excludeFloor.split(',')
                     for (let j=0; j<this.floorinfo.excludeFloor.length; j++) {
                        for (let i=0; i< this.addfloors.length; i++) {
                           if (Number(this.addfloors[i].value) == Number(this.floorinfo.excludeFloor[j])) {
                              this.addfloors.splice(i, 1);
                           }
                        }
                     }
               }else if (this.btntype == '批量' ){
                  this.addfloors = floors.filter(item=>{
                     return Number(item.value) > 0
                  })
                     this.floorinfo.excludeFloor = this.floorinfo.excludeFloor.split(',')
                     for (let j=0; j<this.floorinfo.excludeFloor.length; j++) {
                        for (let i=0; i< this.addfloors.length; i++) {
                           if (Number(this.addfloors[i].value) == Number(this.floorinfo.excludeFloor[j])) {
                              this.addfloors.splice(i, 1);
                           }
                        }
                     }
               }else {
                  this.floors = floors.filter(item => {
                     return item.value != 0
                  })
                     this.floorinfo.excludeFloor = this.floorinfo.excludeFloor.split(',')
                     for (let j=0; j<this.floorinfo.excludeFloor.length; j++) {
                        for (let i=0; i< this.floors.length; i++) {
                           if (Number(this.floors[i].value) == Number(this.floorinfo.excludeFloor[j])) {
                              this.floors.splice(i, 1);
                           }
                        }
                  }
               }
            }
         })
      },
      //遍历时获取房间列表
      getRoomslist(){
         this.addroomformlist.buildUnitId = this.addbuildUnitIdQ[1]
         this.addroomformlist.hotelId = this.hotelInfo.id
         const url = pms.getroomlist
         const param = {
            hotelId: this.hotelInfo.id,
            buildUnitId: this.addroomformlist.buildUnitId,
            floor: this.addroomformlist.floor,
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.roomslist = res.records
            }
         })
      },
      //删除遍历里面的预览房间
      deleteroomlist(i){
         this.newroomlist.splice(i,1)
      },
      //获取批量房间列表
      getaddroomlist(){
         this.checkbox = false
         this.newroomlist= []
         if(this.except.indexOf(4) != -1 && this.except.indexOf(7) != -1){
            let roomlength = this.roomlength
            for (let i = 1;i <= roomlength ; i++){
               if (/4|7$/.test(i)){
                  roomlength++
               }
               if (i<10 && !/4|7$/.test(i)){
                  this.newroomlist.push({roomNo:this.roomNo+0+i,roomTypeId:this.roomTypeId,})
               }else if (i>=10 && !/4|7$/.test(i)){
                  this.newroomlist.push({roomNo:this.roomNo+i,roomTypeId:this.roomTypeId,})
               }
               this.newroomlist.forEach((item,index)=>{
                  this.roomslist.forEach(items=>{
                     if (item.roomNo == items.roomNo){
                        this.newroomlist.splice(index,1)
                        roomlength++
                     }
                  })
               })
            }
         }else if(this.except.indexOf(7) != -1){
            let roomlength = this.roomlength
            for (let i = 1;i <= roomlength; i++){
               if (/7$/.test(i)){
                  roomlength++
               }
               if (i<10 && !/7$/.test(i)){
                  this.newroomlist.push({roomNo:this.roomNo+0+i,roomTypeId:this.roomTypeId,})
               }else if (i>=10 && !/7$/.test(i)){
                  this.newroomlist.push({roomNo:this.roomNo+i,roomTypeId:this.roomTypeId,})
               }
               this.newroomlist.forEach((item,index)=>{
                  this.roomslist.forEach(items=>{
                     if (this.newroomlist[index].roomNo == this.roomslist[i].roomNo){
                        this.newroomlist.splice(index,1)
                        roomlength++
                     }
                  })
               })
            }
         }else if( this.except.indexOf(4) != -1){
            let roomlength = this.roomlength
            for (let i = 1; i <= roomlength; i++) {
               if (/4$/.test(i)){
                  roomlength++
               }
               if (i < 10 && !/4$/.test(i)) {
                  this.newroomlist.push({roomNo: this.roomNo + 0 + i, roomTypeId: this.roomTypeId,})
               } else if (i >= 10 && !/4$/.test(i)) {
                  this.newroomlist.push({roomNo: this.roomNo + i, roomTypeId: this.roomTypeId,})
               }
               this.newroomlist.forEach((item,index)=>{
                  this.roomslist.forEach(items=>{
                     if (item.roomNo == items.roomNo){
                        this.newroomlist.splice(index,1)
                        roomlength++
                     }
                  })
               })
            }
         }else{
            let roomlength = this.roomlength
            for (let i = 1;i <= roomlength; i++){
               if (i<10){
                  this.newroomlist.push({roomNo:this.roomNo+0+i,roomTypeId:this.roomTypeId,})
               }else{
                  this.newroomlist.push({roomNo:this.roomNo+i,roomTypeId:this.roomTypeId,})
               }
               this.newroomlist.forEach((item,index)=>{
                  this.roomslist.forEach(items=>{
                     if (item.roomNo == items.roomNo){
                        this.newroomlist.splice(index,1)
                        roomlength++
                     }
                  })
               })
            }
         }
      },
     blur(e){ this.addroomform.roomNo = e.target.value },
      //关闭批量弹窗
      batchdialog(){
         this.addcenterDialogVisible = false
         this.newroomlist = ''
         this.addbuildUnitIdQ = ''
         this.addroomformlist.floor = ''
         this.addroomformlist.pmsRooms = ''
         this.addroomformlist.hotelId = ''
         this.except  = []
         this.roomlength = ''
         this.roomNo = ''
         this.roomTypeId = ''
         this.btntype = ''
         this.checkbox = true
      },
      //修改楼层
      editUnit(id){
         const url = pms.checkRoomCount
         let params = {
            hotelId:this.hotelInfo.id,
            buildUnitId:this.floorinfo.id,
            floor:id
         }
         this.$axios.post(url, params, 'json').then(res => {
            if (res.records == 0) {
               if (this.floorinfo.excludeFloor == undefined){
                  this.floorinfo.excludeFloor = []
               }
               this.floorinfo.excludeFloor.push(JSON.stringify(id))
               const url = urlObj.editUnit
               let param = {
                  excludeFloor:this.floorinfo.excludeFloor.join(','),
                  buildId:this.floorinfo.buildId,
                  unitName:this.floorinfo.unitName,
                  id:this.floorinfo.id,
                  beginFloor:this.floorinfo.beginFloor,
                  endFloor:this.floorinfo.endFloor
               }
               this.$axios.post(url, param).then(res => {
                  if (res.success) {
                     this.$message.success('删除成功')
                     this.getFloors(this.buildUnitIdQ[1])
                  }
               })
            }else{
               this.$message.error('该楼层已存在房间，不能删除')
            }
         })
      },
      // 获取楼栋单元层级
      changeBuilding(val){
         this.getFloors(val[1])
      },
      // 获取楼栋单元层级
      addchangeBuilding(val){
         this.getFloors(val[1])
      },
      //添加楼层
      addfroollist(){
         if (this.frool == ''){
            this.$message.error('请输入所要添加的楼层')
         }else{
            //小于最低楼层
            if (Number(this.frool) < Number(this.floorinfo.beginFloor)){
               this.floorinfo.beginFloor = this.frool
               //大于最高楼层
            }else if(Number(this.frool) > Number(this.floorinfo.endFloor)){
               this.floorinfo.endFloor  = this.frool
               //添加已删除的楼层时
            }
            this.floorinfo.excludeFloor = this.floorinfo.excludeFloor.filter(item=>{
               return Number(item) != this.frool
            })
            const url = urlObj.editUnit
            let param = {
               excludeFloor:this.floorinfo.excludeFloor.join(','),
               buildId:this.floorinfo.buildId,
               unitName:this.floorinfo.unitName,
               id:this.floorinfo.id,
               beginFloor:this.floorinfo.beginFloor,
               endFloor:this.floorinfo.endFloor
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.getFloors(this.buildUnitIdQ[1])
                  this.$message.success('添加成功')
               }
            }).catch(error => {
               this.$message.error(error)
            })
         }
      },
      // 改变显示条数
      changePageNum(num){
         this.limit = num
         this.getRooms()
      },
      // 改变当前页
      handleCurrPage (num) {
         this.page = num
         this.getRooms()
      },
      //查看所有房间
      getallroomdata(){
         this.addroomform.buildUnitId = ""
         this.addroomform.floor  = ""
         this.floorQ  = ""
         this.floors = []
         this.addroomform.roomTypeId = ""
         this.buildUnitIdQ = []
         this.getRooms()
      },
      // 获取房间列表
      getRooms(){
         if(this.floorQ != ''){
            this.addroomform.floor = this.floorQ
         }
         this.addroomform.hotelId = this.hotelInfo.id
         const url = pms.getroomlistpage+ `?page=${this.page}&limit=${this.limit}`
         const param = {
            hotelId: this.hotelInfo.id,
            buildUnitId: this.addroomform.buildUnitId,
            floor: this.addroomform.floor,
            roomTypeId: this.addroomform.roomTypeId,
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.loading = false
               this.total = res.total
               this.roomlist = res.records
               this.page = 1
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      // 点击当前楼层
      getFloorClick(val) {
         this.floorQ = val.value
         this.getRooms()
      },
      //添加/编辑/删除 弹窗
      getbtntype(type,val){
         this.btntype =  type
         if (this.btntype =="删除"){
            this.item_id = val.id
         }
         if (this.btntype =="编辑"){
            this.disabled = true
            this.addbuildUnitIdQ = val.buildUnitId
            this.addroomform.buildUnitId = val.buildUnitId
            this.addroomform.floor = val.floor
            this.addroomform.roomNo = val.roomNo
            this.addroomform.roomTypeId = val.roomTypeId
            this.addroomform.remark = val.remark
            this.addroomform.hotelId = val.hotelId
            this.addroomform.id = val.id
         }
         if (this.btntype == '批量'){
            this.addcenterDialogVisible = true
         }else{
            this.centerDialogVisible = true
         }
      },
      //获取房型列表
      getroomtypelist(){
         const url = pms.pmsRoomTypes
         const param = {
            hotelId:this.hotelInfo.id
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.roomtypelist = res.records
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      // 获取添加后同楼层房间列表
      getsoomeRooms(){
         this.addroomform.buildUnitId = this.addbuildUnitIdQ[1]
         if(this.floorQ != ''){
            this.addroomform.floor = this.floorQ
         }
         this.addroomform.hotelId = this.hotelInfo.id
         const url = pms.getroomlistpage+ `?page=${this.page}&limit=${this.limit}`
         const param = {
            hotelId: this.hotelInfo.id,
            buildUnitId: this.addroomform.buildUnitId,
            floor: this.addroomform.floor,
            roomTypeId: this.addroomform.roomTypeId,
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.loading = false
               this.total = res.total
               this.roomlist = res.records
               this.page = 1
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      //添加房间
      addroomlist(){
        this.addroomform.buildUnitId = this.addbuildUnitIdQ[1]
        if (this.addbuildUnitIdQ.length == 0){
          this.$message.error('请选择楼栋')
        }
        this.$refs['addroomform'].validate(valid=>{
          if(valid) {
            const url = pms.addroomlist
            const param = this.addroomform
            this.$axios.post(url, param, 'json').then(res => {
              if (res.success) {
                this.$message.success('添加成功')
                this.centerDialogVisible = false
                this.getsoomeRooms()
              }
            }).catch(error => {
              this.$message.error(error)
            })
          }
        })

      },
      //批量添加房间
      addroomlistdata(){
         this.addroomformlist.buildUnitId = this.addbuildUnitIdQ[1]
         this.addroomformlist.pmsRooms = this.newroomlist
         this.addroomformlist.hotelId =  this.hotelInfo.id
         if(this.addroomformlist.buildUnitId == ""){
            this.$message.error('请选择单元')
           return
         }
         if (this.addroomformlist.floor == ''){
            this.$message.error('请选择楼层')
           return;
         }
         if (this.roomTypeId == '') {
           this.$message.error('请选择房型')
           return;
         }
            const url = pms.addroomlistdata
            const param = this.addroomformlist
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.$message.success('添加成功')
                  this.batchdialog()
                  this.getRooms()
               }
            }).catch(error => {
               this.$message.error(error)
            })
      },
      //修改房间
      editroomitem(){
         const url = pms.editroomitem
         const param = this.addroomform
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.$message.success('修改成功')
               this.centerDialogVisible = false
               this.getRooms()
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      //关闭弹窗后
      closed(){
         this.item_id = ''
         this.addroomform.remark = ""
         this.addroomform.roomNo = ""
         this.addroomform.roomTypeId = ""
         this.addroomform.floor = ''
         this.addroomform.buildUnitId = ''
         this.addroomform.id = ''
         this.roomTypeId = ''
         this.addbuildUnitIdQ = ''
         this.btntype = ""
         this.addfloors = ''
         this.disabled = false
      },
      //删除房间
      deleteitem(){
         const url = pms.deletelroom+this.item_id
         const param = {}
         this.$axios.get(url, param).then(res => {
            if (res.success) {
               this.$message.success('删除成功')
               this.item_id = ""
               this.centerDialogVisible = false
               this.getRooms()
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
   },
}

</script>

<style scoped lang="scss">
.room-set-cont{
   .form-dialog-box .el-form-item{
      width: 100%;
   }
   .page{
      width: 100%;
      display: flex;
   }

   .addbox{
      width: 310px;
      background: #fff;
      min-height: 400px;
   }

   .top{
      height: 40px;
      padding:10px 10px;
      line-height: 40px;
      display: flex;
   }

   .frool{
      padding: 10px;
   }

   .item{
      margin: 15px 0;
      background:#e7f5f7;
   }

   .left{
      background: #fff;
      padding: 30px;
   }

   .right{
      margin-left: 30px;
      width:100%;

   }

   .right-box{
      min-height: 360px;
      max-height: 100%;
      min-width: 600px;
      max-width: 100%;
      background: #fff;
      padding:20px 50px;

   }

   .right-title{
      height: 40px;
      padding: 25px 10px;
      line-height: 40px;

   }

   .froolnum{
      color: #000;
      font-weight: 900;
      font-size: 20px;

   }

   .tips{
      margin-left: 10px;
      font-size: 12px;
      color: red;

   }

   .delet_text{
      padding: 20px;
      text-align: center;
      display: inline-block;
      font-size: 18px;
      width: 95%;
   }

   .delicon{
      position: absolute;
      top: 0;
      right: 0;
      color: red;
      font-weight: 900;
      font-size: 20px;
      cursor: pointer;
      line-height: 10px;
   }

   .somebtn{
      background: -moz-linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
      background: -webkit-linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
      background: -o-linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
      background: -ms-linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
      background: linear-gradient(to right, #037dfb 0%,#4aa2fd 100%);
   }

   /deep/ .el-table th.gutter{
      /*

      * 解决element-ui 表格篡位的问题 👇

      */
      display: table-cell!important;
   }

   ///deep/ .el-table--enable-row-transition .el-table__body td{
   //   text-align: center;
   //}

   /deep/ .el-table th.is-leaf, .el-table td{
      //text-align: center;
      background: #f5f8f9;
      font-size: 17px;
   }

}
</style>
